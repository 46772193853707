import axios from "axios";
import {piniaStore} from "@/store/pinia";
import Config from "@/apis/Config";

export default class TimeTrackingApi {
    /* http;
    store;
    constructor() {
        this.store = piniaStore();
        this.http = axios.create({
            baseURL: Config.baseUrl(),
            headers: {
                "Authorization": `Bearer ${this.store.keycloak.token}`
            }
        });
        this.http.interceptors.request.use((config) => {
            config.baseURL = Config.baseUrl();
            config.headers = {
                "Authorization": `Bearer ${this.store.keycloak.token}`
            };
            return config;
        });
    } */

    getTasks(sortAscending) {
        if(sortAscending) {
            return axios.get("/api/v1/tasks/?sortOrder=ascending");
        }
        return axios.get("/api/v1/tasks/");
    }

    getTaskByID(id) {
        return axios.get("/api/v1/tasks/" + id);
    }

    getUsersTasks(sortAscending) {
        let params = "";
        if(sortAscending) {
            params = "?sortOrder=ascending";
        }
        return axios.get('/api/v1/tasks/current' + params);
    }

    getTeamTasks(id) {
        return axios.get('/api/v1/tasks/team/' + id);
    }

    createTask(task) {
        return axios.post('/api/v1/tasks/', task);
    }

    updateTask(task) {
        return axios.put('/api/v1/tasks/' + task.id, task);
    }

    startTask(id) {
        return axios.post('/api/v1/tasks/start/' +id, {});
    }
    stopTask(id) {
        return axios.post('/api/v1/tasks/stop/' +id, {});
    }

    finishTask(id) {
        return axios.post('/api/v1/tasks/finish/' + id, {});
    }

    stopAllRunningTasks() {
        return axios.post("/api/v1/tasks/stopThemAll", {});
    }

    getTimetrackEvent(id) {
        return axios.get("/api/v1/timetrackevents/" + id);
    }

    createTimetrackEvent(event) {
        return axios.post("/api/v1/timetrackevents", event);
    }

    updateTimetrackEvent(event) {
        return axios.put("/api/v1/timetrackevents", event);
    }

    deleteTimetrackEventByID(id) {
        return axios.delete("/api/v1/timetrackevents/" + id);
    }

    getUserState() {
        return axios.get("/api/v1/user-state");
    }
    getUserStateByUserID(userId) {
        return axios.get("/api/v1/user-state/" + userId);
    }

    getTimetrackEventsByParentID(parentID) {
        return axios.get("/api/v1/timetrack/byParentID/" + parentID);
    }

    startTimetrackingTask(taskId) {
        return axios.post("/api/v1/timetrack/start", {
            parentModel: "Task",
            parentId: taskId,
        });
    }

    stopTimetrackingTask(taskId) {
        return axios.post("/api/v1/timetrack/stop", {
            parentModel: "Task",
            parentId: taskId,
        });
    }

    getReports() {
        return axios.get("/api/v1/testreports");
    }
    // not implemented yet:
    getReportsByUserID(id) {
        return axios.get("/api/v1/testreports/user/" + id);
    }
    getReportByDate(date) {
        return axios.get("/api/v1/testreports/date/" + date);
    }

    getReportByID(id) {
        return axios.get("/api/v1/testreports/id/" + id);
    }

    exportReportByID(id) {
        return axios.get("/api/v1/testreports/id/" + id + "/export", {responseType: 'blob'});
        //return axios.get("/api/v1/testreports/id/" + id + "/export");
    }

    deleteReportByID(id) {
        return axios.delete("/api/v1/testreports/" + id);
    }

    createReport(report) {
        return axios.post("/api/v1/testreports", report);
    }
    updateReport(report) {
        return axios.put("/api/v1/testreports", report);
    }
    approveReport(report) {
        return axios.put("/api/v1/testreports/approve", report);
    }
    rejectReport(report) {
        return axios.put("/api/v1/testreports/reject", report);
    }
    submitReport(report) {
        return axios.put("/api/v1/testreports/submit", report);
    }
}